import React from 'react'
import PrivateRoute from '../components/privateroute'
// import queryString from 'query-string';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import TeacherLogin from '../pages/Authentication/TeacherLogin';


import Redirectpage from '../pages/Authentication/Redirectpage'

import ResetPassword from '../pages/Authentication/ResetPassword'
// import AdminLogin from '../pages/Authentication/adminlogin'
// import ForgotPassword from '../pages/Authentication/ForgotPassword';

import Dashboard from '../pages/Home/Teachers/Submodule/Dashboard/index'

import AddTeacher from '../pages/Home/Teachers/AddTeacher'
import Teachers from '../pages/Home/Teachers/index'
import EditTeacher from '../pages/Home/Teachers/EditTeacher'

import TeacherPodcast from '../pages/Home/Teachers/Submodule/Podcast/index'
import TeacherPodcastEdit from '../pages/Home/Teachers/Submodule/Podcast/Edit'
import TeacherAddPodcast from '../pages/Home/Teachers/Submodule/Podcast/Add'

import TeacherEvent from '../pages/Home/Teachers/Submodule/Events/index'
import TeacherEventEdit from '../pages/Home/Teachers/Submodule/Events/Edit'
import TeacherEventAdd from '../pages/Home/Teachers/Submodule/Events/Add'

import TeacherBlog from '../pages/Home/Teachers/Submodule/Blogs/index'
import TeacherBlogEdit from '../pages/Home/Teachers/Submodule/Blogs/EditBlog'
import TeacherBlogAdd from '../pages/Home/Teachers/Submodule/Blogs/AddBlog'

import TeacherCourse from '../pages/Home/Teachers/Submodule/Course/index'
import TeacherCourseEdit from '../pages/Home/Teachers/Submodule/Course/Edit_Course'
import TeacherCourseAdd from '../pages/Home/Teachers/Submodule/Course/Add_Course'

import TeacherCommunity from '../pages/Home/Teachers/Submodule/Community/index'
import TeacherCommunityEdit from '../pages/Home/Teachers/Submodule/Community/Edit_Community'
import TeacherCommunityAdd from '../pages/Home/Teachers/Submodule/Community/Add_Community'

import TeacherFans from '../pages/Home/Teachers/Submodule/Fans/index'
import TeacherFansAdd from '../pages/Home/Teachers/Submodule/Fans/Add_Fans'
import TeacherFansEdit from '../pages/Home/Teachers/Submodule/Fans/Edit_Fans'
// import TeachersCommentSection from '../pages/Home/Teachers/Comments_section'
import FanPageSliders from '../pages/Home/Teachers/Submodule/FansPagesliders';
import Fans_page from '../pages/Home/Teachers/Submodule/Fans/Fans_page';
import AddfanPost from '../pages/Home/Teachers/Submodule/Fans/AddfanPost';




import TeachersMerit from '../pages/Home/Teacher_Merit'
import AddTeacherMerit from '../pages/Home/Teacher_Merit/AddMerit'
import EditTeacherMerit from '../pages/Home/Teacher_Merit/Editmerit'

import TeacherImage from '../pages/Home/Teachers/Submodule/Image/index'
import TeacherImageEdit from '../pages/Home/Teachers/Submodule/Image/Edit'
import TeacherImageAdd from '../pages/Home/Teachers/Submodule/Image/Add'

import TeacherVideo from '../pages/Home/Teachers/Submodule/Video/index'
import TeacherVideoEdit from '../pages/Home/Teachers/Submodule/Video/Edit'

import TeacherReview from '../pages/Home/Teachers/Submodule/Book_Reviews/index'
import TeacherReviewAdd from '../pages/Home/Teachers/Submodule/Book_Reviews/Add_Review'
import TeacherReviewEdit from '../pages/Home/Teachers/Submodule/Book_Reviews/Edit_Review'

import YogaLegend from '../pages/Home/YogaLegends'
import AddYogaLegend from '../pages/Home/YogaLegends/AddLegends'
import EditYogaLegend from '../pages/Home/YogaLegends/EditLegends'

import YogaSchool from '../pages/Home/YogaSchools'
import AddYogaSchool from '../pages/Home/YogaSchools/AddSchool'
import EditYogaSchool from '../pages/Home/YogaSchools/EditSchool'

import SchoolBlog from '../pages/Home/YogaSchools/Submodule/Blogs/index'
import SchoolBlogEdit from '../pages/Home/YogaSchools/Submodule/Blogs/EditBlog'
import SchoolBlogAdd from '../pages/Home/YogaSchools/Submodule/Blogs/AddBlog'

import SchoolBroadCast from '../pages/Home/YogaSchools/Submodule/Broadcast/index'
import SchoolBroadCastEdit from '../pages/Home/YogaSchools/Submodule/Broadcast/Edit'
import SchoolBroadCastAdd from '../pages/Home/YogaSchools/Submodule/Broadcast/Add'

import SchoolEvent from '../pages/Home/YogaSchools/Submodule/Events/index'
import SchoolEventEdit from '../pages/Home/YogaSchools/Submodule/Events/Edit'
import SchoolEventAdd from '../pages/Home/YogaSchools/Submodule/Events/Add'

import SchoolCourse from '../pages/Home/YogaSchools/Submodule/Course/index'
import SchoolCourseEdit from '../pages/Home/YogaSchools/Submodule/Course/Edit_Course'
import SchoolCourseAdd from '../pages/Home/YogaSchools/Submodule/Course/Add_Course'

import SchoolImage from '../pages/Home/YogaSchools/Submodule/Image/index'
import SchoolImageEdit from '../pages/Home/YogaSchools/Submodule/Image/Edit'

import SchoolVideo from '../pages/Home/YogaSchools/Submodule/Video/index'
import SchoolVideoEdit from '../pages/Home/YogaSchools/Submodule/Video/Edit'

import SchoolCommunity from '../pages/Home/YogaSchools/Submodule/Community/index'
import SchoolCommunityEdit from '../pages/Home/YogaSchools/Submodule/Community/Edit_Community'
import SchoolCommunityAdd from '../pages/Home/YogaSchools/Submodule/Community/Add_Community'

import SchoolFans from '../pages/Home/YogaSchools/Submodule/Fans/index'
import SchoolFansAdd from '../pages/Home/YogaSchools/Submodule/Fans/Add_Fans'
import SchoolFansEdit from '../pages/Home/YogaSchools/Submodule/Fans/Edit_Fans'
import School_Fans_page from '../pages/Home/YogaSchools/Submodule/Fans/Fans_page';

import SchoolReview from '../pages/Home/YogaSchools/Submodule/Book_Reviews/index'
import SchoolReviewAdd from '../pages/Home/YogaSchools/Submodule/Book_Reviews/Add_Review'
import SchoolReviewEdit from '../pages/Home/YogaSchools/Submodule/Book_Reviews/Edit_Review'

import YogaStudios from '../pages/Home/YogaStudios'
import AddYogaStudios from '../pages/Home/YogaStudios/AddStudios'
import EditYogaStudios from '../pages/Home/YogaStudios/EditStudios'

import StudioBlog from '../pages/Home/YogaStudios/Submodule/Blogs/index'
import StudioBlogEdit from '../pages/Home/YogaStudios/Submodule/Blogs/EditBlog'
import StudioBlogAdd from '../pages/Home/YogaStudios/Submodule/Blogs/AddBlog'

import StudioBroadCast from '../pages/Home/YogaStudios/Submodule/Broadcast/index'
import StudioBroadCastEdit from '../pages/Home/YogaStudios/Submodule/Broadcast/Edit'
import StudioBroadCastAdd from '../pages/Home/YogaStudios/Submodule/Broadcast/Add'

import StudioEvent from '../pages/Home/YogaStudios/Submodule/Events/index'
import StudioEventEdit from '../pages/Home/YogaStudios/Submodule/Events/Edit'
import StudioEventAdd from '../pages/Home/YogaStudios/Submodule/Events/Add'

import StudioCourse from '../pages/Home/YogaStudios/Submodule/Course/index'
import StudioCourseEdit from '../pages/Home/YogaStudios/Submodule/Course/Edit_Course'
import StudioCourseAdd from '../pages/Home/YogaStudios/Submodule/Course/Add_Course'

import StudioImage from '../pages/Home/YogaStudios/Submodule/Image/index'
import StudioImageEdit from '../pages/Home/YogaStudios/Submodule/Image/Edit'

import StudioVideo from '../pages/Home/YogaStudios/Submodule/Video/index'
import StudioVideoEdit from '../pages/Home/YogaStudios/Submodule/Video/Edit'

import StudioCommunity from '../pages/Home/YogaStudios/Submodule/Community/index'
import StudioCommunityEdit from '../pages/Home/YogaStudios/Submodule/Community/Edit_Community'
import StudioCommunityAdd from '../pages/Home/YogaStudios/Submodule/Community/Add_Community'

import StudioReview from '../pages/Home/YogaStudios/Submodule/Book_Reviews/index'
import StudioReviewAdd from '../pages/Home/YogaStudios/Submodule/Book_Reviews/Add_Review'
import StudioReviewEdit from '../pages/Home/YogaStudios/Submodule/Book_Reviews/Edit_Review'

import StudioFans from '../pages/Home/YogaStudios/Submodule/Fans/index'
import StudioFansAdd from '../pages/Home/YogaStudios/Submodule/Fans/Add_Fans'
import StudioFansEdit from '../pages/Home/YogaStudios/Submodule/Fans/Edit_Fans'
import Studio_Fans_page from '../pages/Home/YogaStudios/Submodule/Fans/Fans_page';

import Events from '../pages/Home/Events/index'
import AddEvent from '../pages/Home/Events/AddEvents'
import EditEvent from '../pages/Home/Events/EditEvent'

import Course from '../pages/Home/Course'
import AddCourse from '../pages/Home/Course/AddCourse'
// import EditCourse from '../pages/Home/Course/EditCourse'

import SubCategory from '../pages/Home/subcategory'
import AddSubCategory from '../pages/Home/subcategory/AddSubcategory'
import EditSubCategory from '../pages/Home/subcategory/EditSubcategory'

import Category from '../pages/Home/Category'
import AddCategory from '../pages/Home/Category/AddCategory'
import EditCategory from '../pages/Home/Category/EditCategory'

import Blogs from '../pages/Home/Blogs/index'
import Addblogs from '../pages/Home/Blogs/AddBlog'
import Editblogs from '../pages/Home/Blogs/EditBlog'
import Comments from '../pages/Home/Comments/comments'

import About from '../pages/Home/About'
import EditAbout from '../pages/Home/About/EditAbout'
import AddAbout from '../pages/Home/About/AddAbout'

import RoleManagement from '../pages/Home/RoleManagement'
import AddRole from '../pages/Home/RoleManagement/Addrole'
import EditRole from '../pages/Home/RoleManagement/Editrole'

import News from '../pages/Home/News/index'
import AddNews from '../pages/Home/News/AddNews'
import EditNews from '../pages/Home/News/EditNews'

// ../pages/Home/JobDetails/index
import Jobs from '../pages/Home/Teachers/Submodule/JobDetails/index'
import AddJobs from '../pages/Home/Teachers/Submodule/JobDetails/AddJobDetails'
import EditJobs from '../pages/Home/Teachers/Submodule/JobDetails/EditJobDetails'

import Subscription from '../pages/Home/SubscriptionManagement'
import AddSubscription from '../pages/Home/SubscriptionManagement/AddSubscription'
import EditSubscription from '../pages/Home/SubscriptionManagement/EditSubscription'

import Community from '../pages/Home/Community'
import AddCommunity from '../pages/Home/Community/AddCommunity'
import EditCommunity from '../pages/Home/Community/EditCommunity'

import CommunityDetails from '../pages/Home/Community/details'
import CommunityRule from '../pages/Home/Community/CommunityRule/CommunityDesc'
import { CommunityProvider } from '../pages/Home/Community/Context/CommunityContext'
import CommunityDescription from '../pages/Home/Community/CommunityDesc/CommunityDesc'
import CommunityAttachment from '../pages/Home/Community/CommunityAttachments/CommunityDesc'
import CommunityCorse from '../pages/Home/Community/CommunityCourse/CommunityDesc'
import CommunityEvent from '../pages/Home/Community/CommunityEvent/CommunityDesc'
import CommunityImage from '../pages/Home/Community/CommunityImages/CommunityDesc'
import CommunityVideo from '../pages/Home/Community/CommunityVideo/CommunityDesc'
import CommunityPodcast from '../pages/Home/Community/CommunityPodcast/CommunityDesc'
import CommunityUsers from '../pages/Home/Community/CommunityUsers/CommunityDesc'
import CommunityAddVideo from '../pages/Home/Community/CommunityVideo/Desc'
import CommunityTableVideo from '../pages/Home/Community/CommunityVideo/CommunityDesc'
import CommunityAddImage from '../pages/Home/Community/CommunityImages/Desc'
import CommunityTableImage from '../pages/Home/Community/CommunityImages/CommunityDesc'
import CommunityTimeline from '../pages/Home/Community/CommunityTimeline/CommunityDesc'

import HomeTab from '../pages/Home/Home_tab/index'
import AddHomeTab from '../pages/Home/Home_tab/Add_HomeTab'
import EditHomeTab from '../pages/Home/Home_tab/Edit_HomeTab'

import Tag from '../pages/Home/Tag'
import AddTag from '../pages/Home/Tag/AddTag'
import EditTag from '../pages/Home/Tag/EditTag'

import YogaQuote from '../pages/Home/YogaQuote/index'
import AddYogaquote from '../pages/Home/YogaQuote/AddYogaquote'
import EditYogaquote from '../pages/Home/YogaQuote/EditYogaquote'

import BookReviews from '../pages/Home/BookReviews/index'
import AddBookreviews from '../pages/Home/BookReviews/AddBookreviews'
import EditBookreviews from '../pages/Home/BookReviews/EditBookreviews'
import BookComments from '../pages/Home/BookComments/bookcomments'

import Podcast from '../pages/Home/Podcast/index'
import AddPodcast from '../pages/Home/Podcast/AddPodcast'
import EditPodcast from '../pages/Home/Podcast/EditPodcast'
import PodcastGridview from '../pages/Home/Podcast/Podcast_cardview'
import PodcastDetails from '../pages/Home/Podcast/Podcast_details'

import Management from '../pages/Home/Management'
import Addmanagement from '../pages/Home/Management/Addmanagement'
import Editmanagement from '../pages/Home/Management/Editmanagement'

import Image from '../pages/Home/Image'
import AddImage from '../pages/Home/Image/AddImage'
import Editimage from '../pages/Home/Image/Editimage'

import Video from '../pages/Home/Video/index'
// import VideoEdit from '../pages/Home/Video/Edit'
import VideoAdd from '../pages/Home/Video/Add'

import Newsletter from '../pages/Home/Newsletter_subcription/index'

import Users from '../pages/Home/Users/index'
import AddUsers from '../pages/Home/Users/AddUser'
import EditUsers from '../pages/Home/Users/EditUser'

import PortalUsers from '../pages/Home/PortalUsers/index'
import AddPortalUsers from '../pages/Home/PortalUsers/AddPortal'
import EditPortalUsers from '../pages/Home/PortalUsers/EditPortal'


import AddLinks from '../pages/Home/SocialMediaLinks/addlinks';
import ViewLinks from '../pages/Home/SocialMediaLinks/viewlinks';
import AddSlider from '../pages/Home/Teachers/Submodule/FansPagesliders/AddSlider';
import EditSlider from '../pages/Home/Teachers/Submodule/FansPagesliders/EditSlider';
import MainIndex from '../pages/Home/Teachers/Submodule/Settings/MainIndex';
import Auth2Factor from '../pages/Authentication/Auth2Factor';
import CourseDetail from '../pages/Home/Teachers/Submodule/Course/CourseDetail';
import AddChapter from '../pages/Home/Teachers/Submodule/Course/CourseDetail/curriculum/addChapter';
import CreateCourse from '../pages/Home/Teachers/Submodule/Course/CreateCourse';
import EditCourse from '../pages/Home/Teachers/Submodule/Course/CourseDetail/EditCourse';
import Couponindex from '../pages/Home/Teachers/Submodule/Settings/Couponsandoffers/Couponindex';
import Datatablecoupons from '../pages/Home/Teachers/Submodule/Settings/Couponsandoffers/Datatablecoupons';
import CouponandOffersettings from '../pages/Home/Teachers/Submodule/Settings/Couponsandoffers/CouponandOffersettings';
import Editcoupon from '../pages/Home/Teachers/Submodule/Settings/Couponsandoffers/Editcoupon';

export default function Routers() {
    return (
        <Router>
            <Routes>


                {/* Auth Routes */}
                <Route exact path='/' element={<TeacherLogin/>}></Route>
                <Route exact path='/Redirectpage/:email/:token/:redirect' element={<Redirectpage/>}></Route>
                <Route exact path='/reset_login' element={<ResetPassword/>}></Route>
                <Route exact path='/login' element={<Auth2Factor/>}></Route>


                {/* <Route exact path='/teacherlogin' element={<TeacherLogin />}></Route> */}
                {/* <Route exact path='/forgetPassword' element={<ForgotPassword/>}></Route> */}



                <Route exact path='/addteacher' element={<PrivateRoute><AddTeacher /></PrivateRoute>}></Route>
                <Route path="/teachers" element={<PrivateRoute><Teachers /> </PrivateRoute>} />
                <Route path="/editteacher" element={<PrivateRoute><EditTeacher /> </PrivateRoute>} />

                <Route path='/teachers/details/podcast/:id' element={<PrivateRoute>< TeacherPodcast /></PrivateRoute>} />
                <Route path='/teachers/details/podcast/add/:id' element={<PrivateRoute>< TeacherAddPodcast /></PrivateRoute>} />
                <Route path='/teachers/details/podcastedit/:id' element={<PrivateRoute>< TeacherPodcastEdit /></PrivateRoute>} />

                <Route path='/teachers/details/events/:id' element={<PrivateRoute>< TeacherEvent /></PrivateRoute>} />
                <Route path='/teachers/details/events/add/:id' element={<PrivateRoute>< TeacherEventAdd /></PrivateRoute>} />
                <Route path='/teachers/details/events/edit/:id' element={<PrivateRoute>< TeacherEventEdit /></PrivateRoute>} />

                <Route path='/teachers/details/blog/:id' element={<PrivateRoute>< TeacherBlog /></PrivateRoute>} />
                <Route path='/teachers/details/blog/add/:id' element={<PrivateRoute>< TeacherBlogAdd /></PrivateRoute>} />
                <Route path='/teachers/details/blog/edit/:id' element={<PrivateRoute>< TeacherBlogEdit /></PrivateRoute>} />

                <Route path='/teachers/details/course/:id' element={<PrivateRoute>< TeacherCourse /></PrivateRoute>} />
                <Route path='/teachers/details/course/add/:id' element={<PrivateRoute>< CreateCourse /></PrivateRoute>} />
                <Route path='/teachers/details/course/edit/:id' element={<PrivateRoute>< EditCourse /></PrivateRoute>} />
                <Route path='/teachers/details/course/edit/:id' element={<PrivateRoute>< TeacherCourseEdit /></PrivateRoute>} />

                <Route path='/teachers/details/bookreview/:id' element={<PrivateRoute>< TeacherReview /></PrivateRoute>} />
                <Route path='/teachers/details/bookreview/add/:id' element={<PrivateRoute>< TeacherReviewAdd /></PrivateRoute>} />
                <Route path='/teachers/details/bookreview/edit/:id' element={<PrivateRoute>< TeacherReviewEdit /></PrivateRoute>} />

                <Route path='/teachers/details/image/:id' element={<PrivateRoute>< TeacherImage /></PrivateRoute>} />
                <Route path='/teachers/details/image/add/:id' element={<PrivateRoute><TeacherImageAdd  /></PrivateRoute>} />
                <Route path='/teachers/details/image/edit/:id' element={<PrivateRoute>< TeacherImageEdit /></PrivateRoute>} />

                <Route path='/teachers/details/video/:id' element={<PrivateRoute>< TeacherVideo /></PrivateRoute>} />
                <Route path='/teachers/details/video/edit/:id' element={<PrivateRoute>< TeacherVideoEdit /></PrivateRoute>} />

                <Route path='/teachers/details/community/:id' element={<PrivateRoute>< TeacherCommunity /></PrivateRoute>} />
                <Route path='/teachers/details/community/add/:id' element={<PrivateRoute>< TeacherCommunityAdd /></PrivateRoute>} />
                <Route path='/teachers/details/community/edit/:id' element={<PrivateRoute>< TeacherCommunityEdit /></PrivateRoute>} />

                <Route path='/teachers/details/fansslidersettings/:id' element={<PrivateRoute><FanPageSliders /></PrivateRoute>} />
                <Route path='/teachers/details/addfanssliders/:id' element={<PrivateRoute><AddSlider /></PrivateRoute>} />
                <Route path='/teachers/details/editfanssliders/:id' element={<PrivateRoute><EditSlider /></PrivateRoute>} />


                <Route path='/teachers/details/fanspagesliders/:id' element={<PrivateRoute>< TeacherFans /></PrivateRoute>} />
                <Route path='/teachers/details/fans/:id' element={<PrivateRoute>< TeacherFans /></PrivateRoute>} />
                <Route path='/teachers/details/fans/add/:id' element={<PrivateRoute>< TeacherFansAdd /></PrivateRoute>} />
                <Route path='/teachers/details/fans/edit/:id' element={<PrivateRoute><TeacherFansEdit /></PrivateRoute>} />
                {/* <Route path='/teachers/details/commentsection' element={<PrivateRoute><TeachersCommentSection/></PrivateRoute>} /> */}
                <Route path='/teachers/fanspage/:id' element={<PrivateRoute><Fans_page /></PrivateRoute>} />
                <Route path='/teachers/fanspage/fanpost/:id/:fanid' element={<PrivateRoute><AddfanPost /></PrivateRoute>} />

                
                <Route path="/teachermerit" element={<PrivateRoute><TeachersMerit /> </PrivateRoute>} />
                <Route path="/addteachermerit" element={<PrivateRoute><AddTeacherMerit /> </PrivateRoute>} />
                <Route path="/editteachermerit" element={<PrivateRoute><EditTeacherMerit /> </PrivateRoute>} />

                <Route path="/yogalegend" element={<PrivateRoute><YogaLegend /> </PrivateRoute>} />
                <Route path="/addyogalegend" element={<PrivateRoute><AddYogaLegend /> </PrivateRoute>} />
                <Route path="/edityogalegend" element={<PrivateRoute><EditYogaLegend /> </PrivateRoute>} />

                <Route path="/yogaschool" element={<PrivateRoute><YogaSchool /> </PrivateRoute>} />
                <Route path="/addyogaschool" element={<PrivateRoute><AddYogaSchool /> </PrivateRoute>} />
                <Route path="/edityogaschool" element={<PrivateRoute><EditYogaSchool /> </PrivateRoute>} />

                <Route path='/yogaschool/details/blog/:id' element={<PrivateRoute>< SchoolBlog /></PrivateRoute>} />
                <Route path='/yogaschool/details/blog/add/:id' element={<PrivateRoute>< SchoolBlogAdd /></PrivateRoute>} />
                <Route path='/yogaschool/details/blog/edit/:id' element={<PrivateRoute>< SchoolBlogEdit /></PrivateRoute>} />

                <Route path='/yogaschool/details/broadcast/:id' element={<PrivateRoute>< SchoolBroadCast /></PrivateRoute>} />
                <Route path='/yogaschool/details/broadcast/add/:id' element={<PrivateRoute>< SchoolBroadCastAdd /></PrivateRoute>} />
                <Route path='/yogaschool/details/broadcast/edit/:id' element={<PrivateRoute>< SchoolBroadCastEdit /></PrivateRoute>} />

                <Route path='/yogaschool/details/events/:id' element={<PrivateRoute>< SchoolEvent /></PrivateRoute>} />
                <Route path='/yogaschool/details/events/add/:id' element={<PrivateRoute>< SchoolEventAdd /></PrivateRoute>} />
                <Route path='/yogaschool/details/events/edit/:id' element={<PrivateRoute>< SchoolEventEdit /></PrivateRoute>} />

                <Route path='/yogaschool/details/course/:id' element={<PrivateRoute>< SchoolCourse /></PrivateRoute>} />
                <Route path='/yogaschool/details/course/add/:id' element={<PrivateRoute>< SchoolCourseAdd /></PrivateRoute>} />
                <Route path='/yogaschool/details/course/edit/:id' element={<PrivateRoute>< SchoolCourseEdit /></PrivateRoute>} />

                <Route path='/yogaschool/details/image/:id' element={<PrivateRoute>< SchoolImage /></PrivateRoute>} />
                <Route path='/yogaschool/details/image/edit/:id' element={<PrivateRoute>< SchoolImageEdit /></PrivateRoute>} />

                <Route path='/yogaschool/details/video/:id' element={<PrivateRoute>< SchoolVideo /></PrivateRoute>} />
                <Route path='/yogaschool/details/video/edit/:id' element={<PrivateRoute>< SchoolVideoEdit /></PrivateRoute>} />

                <Route path='/yogaschool/details/community/:id' element={<PrivateRoute>< SchoolCommunity /></PrivateRoute>} />
                <Route path='/yogaschool/details/community/add/:id' element={<PrivateRoute>< SchoolCommunityAdd /></PrivateRoute>} />
                <Route path='/yogaschool/details/community/edit/:id' element={<PrivateRoute>< SchoolCommunityEdit /></PrivateRoute>} />

                <Route path='/yogaschool/details/bookreview/:id' element={<PrivateRoute>< SchoolReview /></PrivateRoute>} />
                <Route path='/yogaschool/details/bookreview/add/:id' element={<PrivateRoute>< SchoolReviewAdd /></PrivateRoute>} />
                <Route path='/yogaschool/details/bookreview/edit/:id' element={<PrivateRoute>< SchoolReviewEdit /></PrivateRoute>} />

                <Route path='/yogaschool/details/fans/:id' element={<PrivateRoute>< SchoolFans /></PrivateRoute>} />
                <Route path='/yogaschool/details/fans/add/:id' element={<PrivateRoute>< SchoolFansAdd /></PrivateRoute>} />
                <Route path='/yogaschool/details/fans/edit/:id' element={<PrivateRoute><SchoolFansEdit /></PrivateRoute>} />
                <Route path='/yogaschool/fanspage/:id' element={<PrivateRoute><School_Fans_page /></PrivateRoute>} />

                <Route path="/yogastudios" element={<PrivateRoute><YogaStudios /> </PrivateRoute>} />
                <Route path="/addyogastudios" element={<PrivateRoute><AddYogaStudios /> </PrivateRoute>} />
                <Route path="/edityogastudios" element={<PrivateRoute><EditYogaStudios /> </PrivateRoute>} />

                <Route path='/yogastudios/details/blog/:id' element={<PrivateRoute>< StudioBlog /></PrivateRoute>} />
                <Route path='/yogastudios/details/blog/add/:id' element={<PrivateRoute>< StudioBlogAdd /></PrivateRoute>} />
                <Route path='/yogastudios/details/blog/edit/:id' element={<PrivateRoute>< StudioBlogEdit /></PrivateRoute>} />

                <Route path='/yogastudios/details/broadcast/:id' element={<PrivateRoute>< StudioBroadCast /></PrivateRoute>} />
                <Route path='/yogastudios/details/broadcast/add/:id' element={<PrivateRoute>< StudioBroadCastAdd /></PrivateRoute>} />
                <Route path='/yogastudios/details/broadcast/edit/:id' element={<PrivateRoute>< StudioBroadCastEdit /></PrivateRoute>} />

                <Route path='/yogastudios/details/events/:id' element={<PrivateRoute>< StudioEvent /></PrivateRoute>} />
                <Route path='/yogastudios/details/events/add/:id' element={<PrivateRoute>< StudioEventAdd /></PrivateRoute>} />
                <Route path='/yogastudios/details/events/edit/:id' element={<PrivateRoute>< StudioEventEdit /></PrivateRoute>} />

                <Route path='/yogastudios/details/course/:id' element={<PrivateRoute>< StudioCourse /></PrivateRoute>} />
                <Route path='/yogastudios/details/course/add/:id' element={<PrivateRoute>< StudioCourseAdd /></PrivateRoute>} />
                <Route path='/yogastudios/details/course/edit/:id' element={<PrivateRoute>< StudioCourseEdit /></PrivateRoute>} />

                <Route path='/yogastudios/details/image/:id' element={<PrivateRoute>< StudioImage /></PrivateRoute>} />
                <Route path='/yogastudios/details/image/edit/:id' element={<PrivateRoute>< StudioImageEdit /></PrivateRoute>} />

                <Route path='/yogastudios/details/video/:id' element={<PrivateRoute>< StudioVideo /></PrivateRoute>} />
                <Route path='/yogastudios/details/video/edit/:id' element={<PrivateRoute>< StudioVideoEdit /></PrivateRoute>} />

                <Route path='/yogastudios/details/bookreview/:id' element={<PrivateRoute>< StudioReview /></PrivateRoute>} />
                <Route path='/yogastudios/details/bookreview/add/:id' element={<PrivateRoute>< StudioReviewAdd /></PrivateRoute>} />
                <Route path='/yogastudios/details/bookreview/edit/:id' element={<PrivateRoute>< StudioReviewEdit /></PrivateRoute>} />

                <Route path='/yogastudios/details/community/:id' element={<PrivateRoute>< StudioCommunity /></PrivateRoute>} />
                <Route path='/yogastudios/details/community/add/:id' element={<PrivateRoute>< StudioCommunityAdd /></PrivateRoute>} />
                <Route path='/yogastudios/details/community/edit/:id' element={<PrivateRoute>< StudioCommunityEdit /></PrivateRoute>} />

                <Route path='/yogastudios/details/fans/:id' element={<PrivateRoute>< StudioFans /></PrivateRoute>} />
                <Route path='/yogastudios/details/fans/add/:id' element={<PrivateRoute>< StudioFansAdd /></PrivateRoute>} />
                <Route path='/yogastudios/details/fans/edit/:id' element={<PrivateRoute><StudioFansEdit /></PrivateRoute>} />
                <Route path='/yogastudios/fanspage/:id' element={<PrivateRoute><Studio_Fans_page /></PrivateRoute>} />

                <Route path="/events" element={<PrivateRoute><Events /> </PrivateRoute>} />
                <Route path="/addevents" element={<PrivateRoute><AddEvent /> </PrivateRoute>} />
                <Route path="/editEvent" element={<PrivateRoute><EditEvent /> </PrivateRoute>} />

                <Route path="/course" element={<PrivateRoute><Course /> </PrivateRoute>} />
                <Route path="/addcourse" element={<PrivateRoute><AddCourse /> </PrivateRoute>} />
                <Route path="/editcourse" element={<PrivateRoute><EditCourse /> </PrivateRoute>} />

                <Route path="/subcategory" element={<PrivateRoute><SubCategory /> </PrivateRoute>} />
                <Route path="/addsubcategory" element={<PrivateRoute><AddSubCategory /> </PrivateRoute>} />
                <Route path="/editsubcategory" element={<PrivateRoute><EditSubCategory /> </PrivateRoute>} />

                <Route path="/category" element={<PrivateRoute><Category /> </PrivateRoute>} />
                <Route path="/addcategory" element={<PrivateRoute><AddCategory /> </PrivateRoute>} />
                <Route path="/editcategory" element={<PrivateRoute><EditCategory /> </PrivateRoute>} />

                <Route path="/blogs" element={<PrivateRoute><Blogs /> </PrivateRoute>} />
                <Route path="/addblogs" element={<PrivateRoute><Addblogs /> </PrivateRoute>} />
                <Route path="/editblogs" element={<PrivateRoute><Editblogs /> </PrivateRoute>} />
                <Route path="/blogs/comments" element={<PrivateRoute><Comments /> </PrivateRoute>} />

                <Route path='/about' element={<PrivateRoute><About /> </PrivateRoute>} />
                <Route path='/addabout' element={<PrivateRoute><AddAbout /> </PrivateRoute>} />
                <Route path='/editabout' element={<PrivateRoute><EditAbout /> </PrivateRoute>} />

                <Route path='/rolemanagement' element={<PrivateRoute><RoleManagement /> </PrivateRoute>} />
                <Route path='/addrole' element={<PrivateRoute><AddRole /> </PrivateRoute>} />
                <Route path='/editrole' element={<PrivateRoute><EditRole /> </PrivateRoute>} />

                <Route path='/subscription' element={<PrivateRoute><Subscription /> </PrivateRoute>} />
                <Route path='/addsubscription' element={<PrivateRoute><AddSubscription /> </PrivateRoute>} />
                <Route path='/editsubscription' element={<PrivateRoute><EditSubscription /> </PrivateRoute>} />

                <Route path='/news' element={<PrivateRoute><News /> </PrivateRoute>} />
                <Route path='/addnews' element={<PrivateRoute><AddNews /> </PrivateRoute>} />
                <Route path='/editnews' element={<PrivateRoute><EditNews /> </PrivateRoute>} />

                <Route path='/teachers/details/career/:id' element={<PrivateRoute><Jobs /> </PrivateRoute>} />
                <Route path='/teachers/details/career/addjobdetails/:id' element={<PrivateRoute><AddJobs /> </PrivateRoute>} />
                <Route path='/teachers/details/career/editjobdetails/:id' element={<PrivateRoute><EditJobs /></PrivateRoute>} />

                <Route path='/community' element={<PrivateRoute><Community /> </PrivateRoute>} />
                <Route path='/addcommunity' element={<PrivateRoute><AddCommunity /> </PrivateRoute>} />
                <Route path='/editcommunity' element={<PrivateRoute><EditCommunity /> </PrivateRoute>} />

                {/* 
                <Route path='/community' element={<Community />} />
                <Route path='/addcommunity' element={<AddCommunity />} />
                <Route path='/editcommunity' element={<EditCommunity />} /> */}

                <Route path="/hometab" element={<PrivateRoute><HomeTab /> </PrivateRoute>} />
                <Route path="/addhometab" element={<PrivateRoute><AddHomeTab /> </PrivateRoute>} />
                <Route path="/edithometab" element={<PrivateRoute><EditHomeTab /> </PrivateRoute>} />

                <Route path="/tag" element={<PrivateRoute><Tag /> </PrivateRoute>} />
                <Route path="/addtag" element={<PrivateRoute><AddTag /> </PrivateRoute>} />
                <Route path="/edittag" element={<PrivateRoute><EditTag /> </PrivateRoute>} />

                <Route path="/yogaquote" element={<PrivateRoute><YogaQuote /> </PrivateRoute>} />
                <Route path="/addyogaquote" element={<PrivateRoute><AddYogaquote /> </PrivateRoute>} />
                <Route path="/edityogaquote" element={<PrivateRoute><EditYogaquote /> </PrivateRoute>} />

                <Route path="/bookreviews" element={<PrivateRoute><BookReviews /> </PrivateRoute>} />
                <Route path="/addbookreviews" element={<PrivateRoute><AddBookreviews /> </PrivateRoute>} />
                <Route path="/editbookreviews" element={<PrivateRoute><EditBookreviews /> </PrivateRoute>} />
                <Route path="/bookreviews/bookcomment" element={<PrivateRoute><BookComments /> </PrivateRoute>} />

                <Route path="/podcast" element={<PrivateRoute><Podcast /> </PrivateRoute>} />
                <Route path="/podcast/addpodcast" element={<PrivateRoute><AddPodcast /> </PrivateRoute>} />
                <Route path="/podcast/editpodcast" element={<PrivateRoute><EditPodcast /> </PrivateRoute>} />
                <Route path="/podcast/gridview" element={<PrivateRoute><PodcastGridview /> </PrivateRoute>} /> 
                <Route path="/podcast/Podcast-details/:podcastTitle" element={<PrivateRoute><PodcastDetails /> </PrivateRoute>} />

                <Route path="/offers" element={<PrivateRoute><Management /> </PrivateRoute>} />
                <Route path="/addoffer" element={<PrivateRoute><Addmanagement /> </PrivateRoute>} />
                <Route path="/editoffer" element={<PrivateRoute><Editmanagement /> </PrivateRoute>} />

                <Route path="/image" element={<PrivateRoute><Image /> </PrivateRoute>} />
                <Route path="/addimage" element={<PrivateRoute><AddImage /> </PrivateRoute>} />
                <Route path="/editimage" element={<PrivateRoute><Editimage /> </PrivateRoute>} />

                <Route path='/video' element={<PrivateRoute>< Video /></PrivateRoute>} />
                <Route path='/addvideo' element={<PrivateRoute>< VideoAdd /></PrivateRoute>}/>
                {/* <Route path='/editvideo' element={<PrivateRoute>< VideoEdit /></PrivateRoute>} /> */}

                <Route path='/community/community-details/videos/:id' element={<PrivateRoute><CommunityAddVideo /></PrivateRoute>} />
                <Route path='/community/community-details/videotable/:id' element={<PrivateRoute><CommunityTableVideo /></PrivateRoute>} />
                <Route path='/community/community-details/images/:id' element={<PrivateRoute><CommunityAddImage /></PrivateRoute>} />
                <Route path='/community/community-details/imagestable/:id' element={<PrivateRoute><CommunityTableImage /></PrivateRoute>} />

                <Route path='/newsletter' element={<PrivateRoute><Newsletter /></PrivateRoute>}></Route>

                <Route path="/users" element={<PrivateRoute><Users /> </PrivateRoute>} />
                <Route path="/addusers" element={<PrivateRoute><AddUsers /> </PrivateRoute>} />
                <Route path="/editusers" element={<PrivateRoute><EditUsers /> </PrivateRoute>} />

                <Route path="/portalusers" element={<PrivateRoute><PortalUsers /> </PrivateRoute>} />
                <Route path="/addportalusers" element={<PrivateRoute><AddPortalUsers /> </PrivateRoute>} />
                <Route path="/editportalusers" element={<PrivateRoute><EditPortalUsers /> </PrivateRoute>} />

                <Route path="/addsociallinks" element={<PrivateRoute><AddLinks /> </PrivateRoute>} />
                <Route path="/viewlinks" element={<PrivateRoute><ViewLinks /> </PrivateRoute>} />

                <Route path="/dashboard/:id" element={<PrivateRoute><Dashboard /> </PrivateRoute>} />   

                <Route path="/teachers/details/settings/2FA/:id" element={<PrivateRoute><MainIndex /> </PrivateRoute>} />

                <Route path="/teachers/details/settings/addcoupons/:id" element={<PrivateRoute><CouponandOffersettings/> </PrivateRoute>} />
                <Route path="/teachers/details/settings/editcoupons/:id" element={<PrivateRoute><Editcoupon/> </PrivateRoute>} />
                <Route path="/teachers/details/settings/coupons/:id" element={<PrivateRoute><Couponindex/> </PrivateRoute>} />
                
                <Route path="/teachers/details/courses/details/:id" element={<PrivateRoute><CourseDetail /> </PrivateRoute>} />
                <Route path="/teachers/details/courses/curriculum/addchapter" element={<PrivateRoute><AddChapter /> </PrivateRoute>} />
                
                
                
            </Routes>
            <CommunityProvider>
                <Routes>
                    <Route path='/community_details/:coummunity_name' element={<PrivateRoute><CommunityDetails /> </PrivateRoute>} />
                    <Route path='/community/community-details/desc/:id' element={<CommunityDescription />} />
                    <Route path='/community/community-details/attachments/:id' element={<CommunityAttachment />} />
                    <Route path='/community/community-details/course/:id' element={<CommunityCorse />} />
                    <Route path='/community/community-details/event/:id' element={<CommunityEvent />} />
                    <Route path='/community/community-details/gallery/:id' element={<CommunityImage />} />
                    <Route path='/community/community-details/vides/:id' element={<CommunityVideo />} />
                    <Route path='/community/community-details/podcast/:id' element={<CommunityPodcast />} />
                    <Route path='/community/community-details/users/:id' element={<CommunityUsers />} />
                    <Route path='/community/community-details/communitytimeline/:id' element={<CommunityTimeline />} />
                    <Route path='/community/community-details/communityrule/:id' element={<CommunityRule />} />
                </Routes>
            </CommunityProvider>
        </Router>
    )
}
