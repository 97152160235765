import React from 'react';
import { Box, Typography } from '@mui/material';

const ZoomInOut = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh', // Adjust as needed
      }}
    >
      <Typography
        sx={{
          animation: 'zoom-in-zoom-out 1s ease infinite',
          '@keyframes zoom-in-zoom-out': {
            '0%': { transform: 'scale(1)' },
            '50%': { transform: 'scale(1.5)' },
            '100%': { transform: 'scale(1)' },
          },
        }}
      >
        Zoom Me!
      </Typography>
    </Box>
  );
};

export default ZoomInOut;
