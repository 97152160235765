import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import React from "react";
// import Header from '../../../Header'
// import Menu from '../../../Menu'
// import Spacing from '../../../Spacing'
// import { Sheet } from '@mui/joy'
// import Table from '@mui/joy/Table';
import EventTable from "./eventTable";
import CommunityTable from "./communityTable";
import Blog from "./blog";
import News from "./news";
import FansActivity from "./fansActivity";
import BookReview from "./bookreview";
import Jobs from "./jobs";
import OnlineCourse from "./onlinecourse";
import MyMails from "./mymails";
import MiniDrawer from "../../../../../components/drawer";
// import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  return (
    <Box className="companies dashboard">
      <MiniDrawer menuOrder={1} />
      <Box component="section" className="contentWraper">
        <Box className="headingBox">
          <Typography
            component="h4"
            variant="h4"
            className="Submenu-page-heading formTitle"
          >
            Dashboard
          </Typography>
        </Box>
              <Box className="">
                  <Box className="container">
                      <Grid container columnSpacing={4} rowSpacing={4}>
                          <Grid item className="" xs={12} md={4} lg={4}>
                              <EventTable />
                          </Grid>
                          <Grid item className="" xs={12} md={8} lg={8}>
                              <CommunityTable />
                          </Grid>
                          <Grid item className="" xs={12} lg={7}>
                              <Blog />
                          </Grid>
                          <Grid item className="" xs={12} lg={5}>
                              <BookReview />
                          </Grid>
                          {/* <Grid item className="" xs={12} lg={5}>
                              <News />
                          </Grid> */}
                          <Grid item className="" xs={12} lg={12}>
                              <FansActivity />
                          </Grid>
                          {/* <Grid item className="" xs={12} lg={6}>
                              <BookReview />
                          </Grid> */}
                          <Grid item className="" xs={12} lg={12}>
                              <Jobs />
                          </Grid>
                          <Grid item className="" xs={12} lg={12}>
                              <OnlineCourse />
                          </Grid>
                          <Grid item className="" xs={12} lg={12}>
                              <MyMails />
                          </Grid>
                      </Grid>
                  </Box>
              </Box>
      </Box>
    </Box>
  );
}
