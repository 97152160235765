import React, { useRef, useState } from "react";
import PropTypes from 'prop-types';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import MiniDrawer from '../../../../../components/drawer';
import axios from 'axios'
import { useNavigate, useParams, useLocation  } from 'react-router-dom';
import { useSnackbar } from 'notistack'
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Box, Button, IconButton, FormControlLabel, Tooltip, Card, Checkbox, Link, ListItemText, OutlinedInput, Input, Breadcrumbs, TableContainer, Select, TablePagination, MenuItem, Dialog, Typography, Grid, FormControl, TextField, DialogContent, DialogActions, DialogTitle, styled, CardContent, AccordionDetails, AccordionSummary, Accordion, Divider, RadioGroup, Radio, InputAdornment, Tabs, Tab } from '@mui/material';
import { Category, Close, Dvr, ListAlt, WidthFull } from '@mui/icons-material';
import CompareIcon from '@mui/icons-material/Compare';
import 'swiper/css';
import 'swiper/css/pagination';
import { AiOutlineInfoCircle } from 'react-icons/ai'; // Example icon from React Icons
import 'swiper/css/navigation';
import Switch from '@mui/material/Switch';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Sidebar from './../../Sidebar'


function BpRadio(props) {
    return (
        <Radio
            sx={{
                '&:hover': {
                    bgcolor: 'transparent',
                },
            }}
            disableRipple
            color="default"
            checkedIcon={<BpCheckedIcon />}
            icon={<BpIcon />}
            {...props}
        />
    );
}
const BpIcon = styled('span')(({ theme }) => ({
    borderRadius: '50%',
    width: 16,
    height: 16,
    boxShadow:
        theme.palette.mode === 'dark'
            ? '0 0 0 1px rgb(16 22 26 / 40%)'
            : 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: theme.palette.mode === 'dark' ? '#394b59' : '#f5f8fa',
    backgroundImage:
        theme.palette.mode === 'dark'
            ? 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))'
            : 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
    },
    'input:hover ~ &': {
        backgroundColor: theme.palette.mode === 'dark' ? '#30404d' : '#ebf1f5',
    },
    'input:disabled ~ &': {
        boxShadow: 'none',
        background:
            theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
    },
}));

const BpCheckedIcon = styled(BpIcon)({
    backgroundColor: '#f3714d',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
    },
    'input:hover ~ &': {
        backgroundColor: '#f3714d',
    },
});


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const QontoStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
    ...(ownerState.active && {
        color: '#784af4',
    }),
    '& .QontoStepIcon-completedIcon': {
        color: '#784af4',
        zIndex: 1,
        fontSize: 18,
    },
    '& .QontoStepIcon-circle': {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
}));

function QontoStepIcon(props) {
    const { active, completed, className } = props;
    return (
        <QontoStepIconRoot ownerState={{ active }} className={className}>
            {completed ? (
                <Check className="QontoStepIcon-completedIcon" />
            ) : (
                <div className="QontoStepIcon-circle" />
            )}
        </QontoStepIconRoot>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
};

const ColorlibStepIconRoot = styled('div')(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundColor:
            '#f3714d',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
    }),
    ...(ownerState.completed && {
        backgroundColor:
            '#f3714d',
    }),
}));

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor:
                '#f3714d',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));


function ColorlibStepIcon(props) {
    const { active, completed, className } = props;

    const icons = {
        1: <ListAlt />,
        2: <CompareIcon />,
        3: <Dvr />,
    };
    return (
        <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const steps = ['Basic Information', 'Additional Information'];
const options = [
    'Ashtanga Yoga', 'Hatha Yoga', 'Meditation', 'Teacher Training', 'Pranayama', 'Sathsangha', 'Vinyasa', 'Nutrition',
    'Acro Yoga', 'Yin Yoga', 'Philosophy', 'Yoga sutras', 'Yoga Mudra', 'Restrorative Yoga', 'Power Yoga', 'Iyengar Yoga'
  ];

export default function Service_Steppyform() {
    let location = useLocation()

    const auth = JSON.parse(localStorage.getItem("userData"))
    const navigate = useNavigate()
    const headerlocal = {
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        Authorization: localStorage.getItem("token")
    }
    const headerformdata = {
        'Content-Type': 'multipart/form-data',
        Authorization: localStorage.getItem("token")
    }
    const [activeStep, setActiveStep] = React.useState(0);
    const params = useParams()


  

    const { enqueueSnackbar } = useSnackbar()
    const [selectedPlan, setSelectedPlan] = React.useState('')
    const [truebutton, setTruebutton] = React.useState(false)
    const [error, setError] = React.useState({titlemsg:'',amount: "", interval: "" })
    const [subcategoryId, setSubCategoryId] = React.useState('')
    const [imageError, setImageError] = React.useState('')
    const [imageError2, setImageError2] = React.useState('')
    const [imageAddError, setImageAddError] = React.useState('')
    const [state1, setState1] = React.useState(location.state.data.additionalimages)
    const [state2, setState2] = React.useState(location.state.data.additionalimages)
    const [statesingle, setStatesingle] = React.useState([...[],location.state.data.image])
    const [statesingle2, setStatesingle2] = React.useState([...[],location.state.data.image])
    const [state3, setState3] = React.useState([...[],location.state.data.profile])
    const [state4, setState4] = React.useState([...[],location.state.data.profile])
    const [statesingle3, setStatesingle3] = React.useState([...[],location.state.data.profile])
    const [statesingle4, setStatesingle4] = React.useState([...[],location.state.data.profile])
    const [convertedContent, setConvertedContent] = React.useState(null);
    const [description, setDescription] = React.useState(location.state.data.short_description)
    const [currency, setCurrency] = React.useState('USD')
    const [annualcurrency,setAnnualCurrency] = React.useState('USD')
    const [amount, setAmount] = React.useState(location.state.data.amountInMonth)
    const [annualamount,setAnnualAmount] = React.useState(location.state.data.amountInYear)
    const [category, setCategory] = React.useState([])
    const [interval, setInterval] = React.useState('month')
    const [annualinterval,setAnnualInterval] = React.useState('year')
    const [communitytype, setCommunitytype] = React.useState('')
    const [publicFanPage, setPublicFanPage] = useState(location.state.data.public)
    const [title, setTitle] = useState(location.state.data.name)
    const [planId, setPlanId] = useState(location.state.data.stripePlanId)
    const [sathsanga,setSathSanga] = React.useState([...[],location.state.data.sathSangs_conducted])
    const [onlinetrain,setOnlinetrain] = React.useState([...[],location.state.data.online_trainings])
    const [batches,setBathches] = React.useState([...[],location.state.data.pranayama_batches])
    const [seminars,setSeminar] = React.useState([...[],location.state.data.international_yoga_seminars])
    const [paymenttype,setPaymentType] = React.useState([...[],location.state.data.fanspage_paymentype])

    const [tagDataUpdated, setTagDataUpdated] = React.useState([]);
    const [tagUpdated, setTagUpdated] = React.useState([]);
    const newData = [...[],location.state.data.options]

    
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [selectedData, setSelectedData] = React.useState([])
    React.useEffect(() => {
        if(newData.length > 0) {
          const data = JSON.parse(newData).map((item) => {
            return item.option
          })
          setSelectedOptions(data)
          setSelectedData(JSON.parse(newData))
        }
      },[])
    const [selectedTag, setSelectedTag] = React.useState(() => {
        const arrVal = [];
        const tagArr = location.state.data.tags;
        if (tagArr.length > 0) {
          tagArr.map((item) => {
            arrVal.push(item.tagname);
          });
        }
        return arrVal;
      });

    const [newTag, setNewTag] = useState("");
    const [tagError, setTagError] = useState("");
    const [tag, setTag] = React.useState("");

    const validationtag = () => {
        if (!newTag) {
          setTagError("Fill the field to add new tag");
          return false;
        }
        return true;
      };

    const handleAddNewTag = () => {
        var valid = validationtag()
        if (valid == true) {
        let body = {
            tagname: newTag,
            createdby: auth && auth._id,
        };
        axios.post(process.env.REACT_APP_BASE_URL + "tags/addTags",body,headerformdata)
            .then((res) => {
            enqueueSnackbar("Tag added successfully", { variant: "success" });
            const newTagData = { _id: res.data._id, tagname: newTag };
            setTag([...tag, newTagData]);
            setTagDataUpdated([...tagDataUpdated, newTagData]);
            setSelectedTag([...selectedTag, newTag]);
            setNewTag("");
            })
            .catch((err) => {
            enqueueSnackbar(
                err && err.response && err.response.data.message
                ? err.response.data.message
                : "Something went wrong",
                { variant: "error" }
            );
            console.log(err);
            });
        }
    };

    //Tags functionality:
    React.useEffect(() => {
        getTags();
    }, []);

  const getTags = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_BASE_URL + `Tags/GetTags`,
        { headers: headerlocal }
      );
      setTag(response.data.Tag);
      setTagDataUpdated(response.data.Tag);
    } catch (error) {
      console.log(error.response, "error");
    }
  };




    const handleNewTagChange = (e) => {
        setTagError('')
          setNewTag(e.target.value);
      };
  
    const indexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
            return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
    }
    const SingleImage = (e, index) => {
        setImageError('');
        setError('');

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files);

        for (let i = 0; i < fileObj[0].length; i++) {
            const img = new Image();
            img.src = URL.createObjectURL(fileObj[0][i]);

            img.onload = function () {
                if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
                    setImageError('Image size should be less than 1 MB.');
                } else {
                    fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    filepath.push(fileObj[0][i]);

                    var data = statesingle.filter(x => x != null);
                    var data1 = statesingle2.filter(x => x != null);
                    setStatesingle(data);
                    setStatesingle2(data1);
                    setStatesingle(prevValue => prevValue.concat(fileArray));
                    setStatesingle2(prevValue => prevValue.concat(filepath));
                }
            };
        }
    };
    const SingleProfileImage = (e, index) => {
        setImageError2('');
        setError('');

        var fileObj = [];
        var fileArray = [];
        var filepath = [];

        fileObj.push(e.target.files);

        for (let i = 0; i < fileObj[0].length; i++) {
            const img = new Image();
            img.src = URL.createObjectURL(fileObj[0][i]);

            img.onload = function () {
                if (fileObj[0][i].size > 1000000) { // 1 MB in bytes
                    setImageError2('Image size should be less than 1 MB.');
                } else {
                    fileArray.push(URL.createObjectURL(fileObj[0][i]));
                    filepath.push(fileObj[0][i]);

                    var data = statesingle3.filter(x => x != null);
                    var data1 = statesingle4.filter(x => x != null);
                    setStatesingle3(data);
                    setStatesingle4(data1);
                    setStatesingle3(prevValue => prevValue.concat(fileArray));
                    setStatesingle4(prevValue => prevValue.concat(filepath));
                }
            };
        }
    };
    const indexDelete2 = (e, index) => {
        var filteredpreview = statesingle3.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle4.filter((val, i) => {
            return i !== index;
        });
        setStatesingle3(filteredpreview);
        setStatesingle4(filteredraw);
    }

    const indexDelete1 = (e, index) => {
        var filteredpreview = statesingle.filter((value, i) => {
            return i !== index;
        });
        var filteredraw = statesingle2.filter((val, i) => {
            return i !== index;
        });
        setStatesingle(filteredpreview);
        setStatesingle2(filteredraw);
    }


    React.useEffect(() => {
        getTagSelected();
      }, [selectedTag, tagDataUpdated]);
    
      function getTagSelected() {
        var array = [];
        const selectedTags = tagDataUpdated.filter((content) =>
          selectedTag.includes(content.tagname)
        );
        selectedTags.map((item, i) => {
          var val = { tagname: item.tagname, tag_id: item._id };
          Object.assign(item, val);
          array.push(val);
        });
        setTagUpdated(array);
      }

    const handlechangeTagSelected = (event, item) => {
        // setError({ ...error, ["userselect"]: '' })
        const {
          target: { value },
        } = event;
        setSelectedTag(typeof value === "string" ? value.split(",") : value);
        getTagSelected();
      };

//To Add Multiple Image:
const multipleImgChangeMultiple = (e, index) => {
    setImageAddError('')
    var fileObj = [];
    var fileArray = [];
    var filepath = [];
    fileObj.push(e.target.files)
    for (let i = 0; i <= fileObj[0].length; i++) {
        if (i < fileObj[0].length) {
            fileArray.push(URL.createObjectURL(fileObj[0][i]))
            filepath.push(fileObj[0][i])
        }
    }
    var data = state1.filter(x => x != null)
    var data1 = state2.filter(x => x != null)
    setState1(data)
    setState2(data1)
    setState1(prevValue => prevValue.concat(fileArray))
    setState2(prevValue => prevValue.concat(filepath))
}
    
      const MulImgsindexDelete = (e, index) => {
        var filteredpreview = state1.filter((value, i) => {
          return i !== index;
        });
        var filteredraw = state2.filter((val, i) => {
          return i !== index;
        });
        setState1(filteredpreview);
        setState2(filteredraw);
      };

      const handleInputChange = (option) => (event) => {
        // const newValue = event.target.value;
    
        // setInputValues(prevInputValues => {
        //   const index = prevInputValues.findIndex(item => item.option === option);
        //   if (index !== -1) {
        //     // Update existing entry
        //     const updatedInputValues = [...prevInputValues];
        //     updatedInputValues[index] = { option, value: newValue };
        //     return updatedInputValues;
        //   } else {
        //     // Add new entry
        //     return [...prevInputValues, { option, value: newValue }];
        //   }
        // });
        const data = selectedData.map((item) => {
          if(item.option === option)  item.value = event.target.value
          return item
        })
        setSelectedData(data)
      };
    
    
      const findOrInitializeValue = (option) => {
        const item = selectedData.find(i => i.option === option);
        return item ? item.value : '';
      };

    const validate = () => {
        if(!title){
            setError({titlemsg:'This field is required'})
            return false
        }
        if (statesingle2?.length == 0) {
            setImageError("This field is required.")
            return false
        }
        if (statesingle4?.length == 0) {
            setImageError2("This field is required.")
            return false
        }
        if (publicFanPage == true) {
            if (!amount) {
                setError({ amount: "This field is required." })
                return false
            }
            if (!interval) {
                setError({ interval: "This field is required." })
                return false
            }
        }
        return true
    }

    const step2Validation = () => {
        if (state2?.length === 0) {
            setImageAddError("This field is required.");
            return false
        }
        return true
    }

    const onlyNumbers = (e) => { e.target.value = e.target.value.replace(/[^0-9, ,+,-]/g, '') };

    const handleChangeStatus = async (e) => {
        setPublicFanPage(e.target.checked)
    };

    //Dropdown functionality:
const handleChange = (event) => {
    const { value } = event.target;
  
    if (value.length > 4) {
      enqueueSnackbar('You can select up to 4 options only.',{variant:'error'});
      return;
    } 
      
    setSelectedOptions(value);
   
    const newInputValues = value.map(option => ({
      option,
      value: selectedData.find(item => item.option === option)?.value || ''
    }));
    setSelectedData(newInputValues);
  };

 


    const handleEditFanPage = (e, details) => {
        var multipleImage = state2.filter((x) => x != null);
        var valid = validate();
        var valid1 = step2Validation();
        if (valid == true && valid1 == true) {
            const formData = new FormData();
            formData.append('createdby[]', params?.id);
            formData.append("amount", amount);
            formData.append("fanspage_paymentype",paymenttype)
            formData.append("addFanPageFrom",'Teacher')
            formData.append("interval", interval);
            formData.append("short_description", description);
            formData.append("currency", currency);
            // formData.append("addFanPageFrom",'school')
            formData.append("subcategory_id", subcategoryId);
            formData.append("name", title);
            // formData.append("sathSangs_conducted",sathsanga)
            // formData.append("online_trainings",onlinetrain)
            // formData.append("pranayama_batches",batches)
            // formData.append("international_yoga_seminars",seminars)
            formData.append("public", publicFanPage);
            formData.append("options",selectedData ? JSON.stringify(selectedData) : [])
            formData.append("tags",JSON.stringify(tagUpdated.length > 0 ? tagUpdated : []));
            statesingle2.forEach((item) => formData.append("newimage", item));
            statesingle4.forEach((item) => formData.append("profile", item));
            multipleImage?.forEach((item) => { formData.append("additionalimages", item) }) 
            setTruebutton(true);
            axios.put(process.env.REACT_APP_BASE_URL + `FanPage/updateFansPage/${location.state.data._id}`, formData, { headers: headerformdata })
                .then((res) => {
                    setTruebutton(false);
                    enqueueSnackbar("Fan Page updated successfully", { variant: 'success' });
                    navigate(`/teachers/details/fans/${params && params.id}`)
                    setInterval('')
                    setTitle('')
                    setAmount('')
                    setStatesingle([]);
                    setStatesingle2([]);
                    setDescription('');
                    setStatesingle3([]);
                    setStatesingle4([]);
                }).catch((err) => {
                    setTruebutton(false);
                    enqueueSnackbar(err.response && err.response.data.error ? err.response.data.error : "Something went wrong.", { variant: 'error' });
                    console.log(err, "error");
                });
                
        }
    };


//Redirecting To next Step:
    const handleNext = () => {
    
        var valid = validate();
    
        if (valid === true) {
          if (activeStep === 1) {
            const validatation = validate();
            
            if (validatation === true) {
              setActiveStep((prevActiveStep) => prevActiveStep + 1);
            }
          } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
        }
      };

      const handleBack = () => {
        setImageAddError('')
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
      };

    return (
      <Box className={auth.role_id == 1 ? "withSubmenu" : ""}>
        {auth.role_id == 1 ? (
          <MiniDrawer menuOrder={2} submenuOrder={1} />
        ) : (
          <MiniDrawer menuOrder={17} />
        )}
        {/* <Sidebar subtype={9} /> */}
        {auth.role_id == 1 ? <Sidebar subtype={8} /> : ""}
        <Box component="section" className="contentWraper">
          <Grid item xs={12} sm={12} md={8} lg={6}>
            <Box className="whiteContainer p-20">
              <Typography component="h6" className="formTitle mb-20">
                Edit Fan Page
              </Typography>
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                connector={<ColorlibConnector />}
              >
                {steps.map((label) => (
                  <Step key={label}>
                    <StepLabel StepIconComponent={ColorlibStepIcon}>
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>

              {activeStep === 0 && (
                <Box className="steppy_content">
                  <Grid item xs={12} sm={6} md={4} lg={3} className="mb-20">
                    <FormControl variant="standard" fullWidth>
                      <Typography component="div" className="label">
                        {" "}
                        Title{" "}
                        <Typography component="span" className="star">
                          *
                        </Typography>
                      </Typography>
                      <TextField
                        id="filled-size-small"
                        variant="outlined"
                        size="small"
                        className="textfield"
                        placeholder="Title"
                        name="title"
                        value={title}
                        onChange={(e) => {
                          setTitle(e.target.value);
                          setError("");
                        }}
                      />
                      {error.titlemsg && (
                        <p style={{ color: "red" }} className="fz-13 m-0">
                          {error.titlemsg}
                        </p>
                      )}
                    </FormControl>
                  </Grid>

                  <Box className="mb-20">
                    <Typography component="div" className="label">
                      Select Options
                      <Typography component="span" className="star">
                        *
                      </Typography>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        multiple
                        value={selectedOptions}
                        onChange={handleChange}
                        renderValue={(selected) => (
                          <div>{selected.join(", ")}</div>
                        )}
                        className="select"
                      >
                        {options.map((option) => (
                          <MenuItem key={option} value={option}>
                            <Checkbox
                              checked={selectedOptions.indexOf(option) > -1}
                            />
                            <ListItemText primary={option} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Box mt={2}>
                      {selectedOptions.map((option) => (
                        <Box key={option} mb={2}>
                          <Typography>{option}</Typography>
                          <TextField
                            id="filled-size-small"
                            variant="outlined"
                            size="small"
                            className="textfield"
                            placeholder="0"
                            onInput={(e) => onlyNumbers(e)}
                            value={findOrInitializeValue(option)}
                            onChange={handleInputChange(option)}
                          />
                        </Box>
                      ))}
                    </Box>
                  </Box>

                  <Box className="mb-20">
                    <Typography component="div" className="label">
                      Select Tags <Typography component="span"></Typography>
                    </Typography>
                    <FormControl fullWidth>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        className="select"
                        value={selectedTag}
                        onChange={(e) => handlechangeTagSelected(e)}
                        input={<OutlinedInput />}
                        renderValue={(selected) => {
                          const selectedTagss = tagDataUpdated.filter((tag) =>
                            selected.includes(tag.tagname)
                          );
                          if (selectedTagss.length === 0) {
                            return "Any tag";
                          } else if (selectedTagss.length === 1) {
                            return selectedTagss[0].tagname;
                          } else {
                            return `${selectedTagss.length} Tags slected`;
                          }
                        }}
                        MenuProps={MenuProps}
                      >
                        {tag && tag?.length > 0 ? (
                          tag &&
                          tag?.map((name) => (
                            <MenuItem key={name._id} value={name.tagname}>
                              <Checkbox
                                checked={selectedTag.indexOf(name.tagname) > -1}
                              />
                              <ListItemText primary={name.tagname} />
                            </MenuItem>
                          ))
                        ) : (
                          <MenuItem>No tag found</MenuItem>
                        )}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box className="mb-20">
                    <Typography component="div" className="label">
                      New Tag
                    </Typography>
                    <Box className="flex-center align-items-start">
                      <FormControl
                        variant="standard"
                        fullWidth
                        className="flex-1"
                      >
                        <TextField
                          id="filled-size-small"
                          variant="outlined"
                          placeholder="New Tag"
                          size="small"
                          name="newTag"
                          className="textfield mr-10 "
                          value={newTag}
                          onChange={handleNewTagChange}
                        />
                        {tagError && (
                          <p style={{ color: "red" }} className="fz-13 m-0">
                            {tagError}
                          </p>
                        )}
                      </FormControl>
                      <Button
                        onClick={handleAddNewTag}
                        variant="contained"
                        className="theme-btn mt-10"
                      >
                        Add Tag
                      </Button>
                    </Box>
                  </Box>

                  <Grid item xs={12} sm={12} md={12}>
                    <Typography component="div" className="label mb-10">
                      Cover Image
                      <Typography component="span" className="star">
                        {" "}
                        *{" "}
                      </Typography>
                    </Typography>

                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                      {statesingle.length < 1 && (
                        <Button
                          className="multiple_upload_btn"
                          variant="contained"
                          component="label"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FileUploadOutlinedIcon className="fz-18 mr-5" />{" "}
                            Upload
                          </Box>
                          <input
                            accept={"image/png, image/jpg,image/jpeg"}
                            onChange={SingleImage}
                            hidden
                            type="file"
                          />
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  {imageError && (
                    <p style={{ color: "red" }} className="fz-13 m-0">
                      {imageError}
                    </p>
                  )}
                  <Grid item xs={12} sm={12} md={12}>
                    {statesingle.length > 0 && (
                      <Grid
                        container
                        columnSpacing={1}
                        rowSpacing={1}
                        className="multiple_upload_container mb-10"
                      >
                        {statesingle.length > 0
                          ? statesingle.map((x, i) => (
                              <Grid item xs={6} sm={4} md={4} lg={4}>
                                <Box className="uploaded_img">
                                  <Button
                                    className="close_icon"
                                    onClick={(e) => indexDelete1(e, i)}
                                  >
                                    <Close />
                                  </Button>
                                  <img
                                    src={
                                      x?.slice(0, 4) === "blob"
                                        ? x
                                        : process.env.REACT_APP_BASE_URL +
                                          `${x}`
                                    }
                                    alt=""
                                    height={155}
                                    width={195}
                                  />
                                </Box>
                              </Grid>
                            ))
                          : ""}
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      className=" fz-12 mt-10 mb-10"
                      component="p"
                      variant="p"
                    >
                      <strong>Note:</strong> Image size should be more than
                      500px * 600px for better quality picture.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <Typography component="div" className="label mb-10">
                      Profile
                      <Typography component="span" className="star">
                        {" "}
                        *{" "}
                      </Typography>
                    </Typography>

                    <Box className="mb-10 d-flex justify-content-between flex-wrap align-items-center">
                      {statesingle3.length < 1 && (
                        <Button
                          className="multiple_upload_btn"
                          variant="contained"
                          component="label"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FileUploadOutlinedIcon className="fz-18 mr-5" />{" "}
                            Upload
                          </Box>
                          <input
                            accept={"image/png, image/jpg,image/jpeg"}
                            onChange={SingleProfileImage}
                            hidden
                            type="file"
                          />
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  {imageError2 && (
                    <p style={{ color: "red" }} className="fz-13 m-0">
                      {imageError2}
                    </p>
                  )}
                  <Grid item xs={12} sm={12} md={12}>
                    {statesingle3.length > 0 && (
                      <Grid
                        container
                        columnSpacing={1}
                        rowSpacing={1}
                        className="multiple_upload_container mb-10"
                      >
                        {statesingle3.length > 0
                          ? statesingle3.map((x, i) => (
                              <Grid item xs={6} sm={4} md={4} lg={4}>
                                <Box className="uploaded_img">
                                  <Button
                                    className="close_icon"
                                    onClick={(e) => indexDelete2(e, i)}
                                  >
                                    <Close />
                                  </Button>
                                  <img
                                    src={
                                      x?.slice(0, 4) === "blob"
                                        ? x
                                        : process.env.REACT_APP_BASE_URL +
                                          `${x}`
                                    }
                                    alt=""
                                    height={155}
                                    width={195}
                                  />
                                </Box>
                              </Grid>
                            ))
                          : ""}
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      className=" fz-12 mt-10 mb-10"
                      component="p"
                      variant="p"
                    >
                      <strong>Note:</strong> Image size should be more than
                      280px * 280px for better quality picture.
                    </Typography>
                  </Grid>

                  <Box className="mb-20 ">
                    <Typography
                      component="div"
                      variant="body1"
                      className="fz-14 semibold mr-10"
                    >
                      Public Fan Page{" "}
                    </Typography>
                    <FormControlLabel
                      className="m-0"
                      control={
                        <Switch
                          size="small"
                          onChange={(e) => handleChangeStatus(e)}
                          checked={publicFanPage}
                        />
                      }
                    />
                  </Box>

                  <Box className="mb-20 ">
                    <Typography component="div" className="label">
                      Choosed Payment Mode
                    </Typography>
                    <RadioGroup
                      row
                      aria-labelledby="demo-customized-radios"
                      name="payment_type"
                      onChange={(e) => setPaymentType(e.target.value)}
                      value={paymenttype}
                    >
                      {paymenttype == "paid" ? (
                        <FormControlLabel
                          value="paid"
                          className="radio-btns"
                          control={<BpRadio />}
                          label={
                            <Typography className="fz-14">
                              Annual/Monthly
                            </Typography>
                          }
                        />
                      ) : (
                        <FormControlLabel
                          value="free"
                          className="radio-btns"
                          control={<BpRadio />}
                          label={
                            <Typography className="fz-14">Free</Typography>
                          }
                        />
                      )}
                    </RadioGroup>
                  </Box>

                  {paymenttype == "paid" && (
                    <Grid
                      container
                      columnSpacing={2}
                      rowSpacing={2}
                      className="mb-20"
                    >
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <FormControl variant="standard" fullWidth>
                          <Typography component="div" className="label">
                            Monthly Price
                            <Typography component="span" className="star">
                              {" "}
                              *
                            </Typography>
                          </Typography>
                          <TextField
                            id="filled-size-small"
                            variant="outlined"
                            size="small"
                            className="textfield"
                            placeholder="Amount"
                            name="amount"
                            InputProps={{
                              readOnly: true,
                              sx: {
                                color: "gray",
                                fontWeight: "bold",
                                backgroundColor: "#F5F5F5",
                              },
                            }}
                            onInput={(e) => onlyNumbers(e)}
                            value={amount}
                            onChange={(e) => {
                              setAmount(e.target.value);
                              setError("");
                            }}
                          />
                          {error.amount && (
                            <p style={{ color: "red" }} className="fz-13 m-0">
                              {error.amount}
                            </p>
                          )}
                        </FormControl>
                      </Grid>

                      {/* <Grid item xs={12} sm={6} md={4} lg={4}>
											<FormControl fullWidth>
												<Typography component='div' className='label'>Currency<Typography component='span' className='star'></Typography></Typography>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={currency}
                                                    InputProps={{
                                                        readOnly: true,
                                                        sx: {
                                                            color: 'gray',
                                                            fontWeight: 'bold',
                                                            backgroundColor: '#F5F5F5' 
                                                        }
                                                    }}
													placeholder="Select Currency"
													className="select"
													onChange={(e) => { setCurrency(e.target.value); setError(''); }}
												>
													<MenuItem disabled>Select Currency</MenuItem>
										
													<MenuItem value={"USD"}>USD</MenuItem>
												</Select>
                                                 
											</FormControl>
                            </Grid> */}

                      {/* <Grid item xs={12} sm={6} md={4} lg={4}>
											<FormControl fullWidth>
												<Typography component='div' className='label'>Interval<Typography component='span' className='star'></Typography></Typography>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={interval}
													placeholder="Select Interval"
                                                    InputProps={{
                                                        readOnly: true,
                                                        sx: {
                                                            color: 'gray',
                                                            fontWeight: 'bold',
                                                            backgroundColor: '#F5F5F5' 
                                                        }
                                                    }}
													className="select"
													onChange={(e) => { setInterval(e.target.value); setError(''); }}
												>
													<MenuItem disabled value={'sel'}>Select Interval</MenuItem>
													
													<MenuItem value={"monthly"}>Monthly</MenuItem>
												
												</Select>
                                                
											</FormControl>
											{error.interval && <p style={{ color: 'red' }} className="fz-13 m-0">{error.interval}</p>}
										</Grid> */}
                    </Grid>
                  )}

                  {paymenttype == "paid" && (
                    <Grid
                      container
                      columnSpacing={2}
                      rowSpacing={2}
                      className="mb-20"
                    >
                      <Grid item xs={12} sm={6} md={4} lg={4}>
                        <FormControl variant="standard" fullWidth>
                          <Typography component="div" className="label">
                            Annual Price
                            <Typography component="span" className="star">
                              {" "}
                              *
                            </Typography>
                          </Typography>
                          <TextField
                            id="filled-size-small"
                            variant="outlined"
                            size="small"
                            className="textfield"
                            placeholder="Amount"
                            name="amount"
                            InputProps={{
                              readOnly: true,
                              sx: {
                                color: "gray",
                                fontWeight: "bold",
                                backgroundColor: "#F5F5F5",
                              },
                            }}
                            onInput={(e) => onlyNumbers(e)}
                            value={annualamount}
                            onChange={(e) => {
                              setAnnualAmount(e.target.value);
                              setError("");
                            }}
                          />
                          {error.annualamount && (
                            <p style={{ color: "red" }} className="fz-13 m-0">
                              {error.annualamount}
                            </p>
                          )}
                        </FormControl>
                      </Grid>

                      {/* <Grid item xs={12} sm={6} md={4} lg={4}>
											<FormControl fullWidth>
												<Typography component='div' className='label'>Currency<Typography component='span' className='star'></Typography></Typography>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={currency}
                                                    InputProps={{
                                                        readOnly: true,
                                                        sx: {
                                                            color: 'gray',
                                                            fontWeight: 'bold',
                                                            backgroundColor: '#F5F5F5' 
                                                        }
                                                    }}
													placeholder="Select Currency"
													className="select"
													onChange={(e) => { setCurrency(e.target.value); setError(''); }}
												>
													<MenuItem disabled>Select Currency</MenuItem>
													<MenuItem value={"USD"}>USD</MenuItem>
												</Select>
                                               
											</FormControl>
                                        </Grid> */}

                      {/* <Grid item xs={12} sm={6} md={4} lg={4}>
											<FormControl fullWidth>
												<Typography component='div' className='label'>Interval<Typography component='span' className='star'></Typography></Typography>
												<Select
													labelId="demo-simple-select-label"
													id="demo-simple-select"
													value={interval}
                                                    InputProps={{
                                                        readOnly: true,
                                                        sx: {
                                                            color: 'gray',
                                                            fontWeight: 'bold',
                                                            backgroundColor: '#F5F5F5' 
                                                        }
                                                    }}
													placeholder="Select Interval"
													className="select"
													onChange={(e) => { setInterval(e.target.value); setError(''); }}
												>
													<MenuItem disabled value={'sel'}>Select Interval</MenuItem>
													<MenuItem value={"year"}>Yearly</MenuItem>
												</Select>
                                              
											</FormControl>
										</Grid> */}
                    </Grid>
                  )}

                  {/* {truebutton === false ?
                                <>
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>

                                        <Button variant="contained" className='addbtn mr-10' onClick={(e) => handleEditFanPage(e, 'add')}>Save</Button>
                                    </Box>
                                </>
                                :
                                truebutton === true &&
                                <>
                                    <Box className='heading-btns mt-20' style={{ textAlign: 'end' }}>

                                        <Button variant="contained" className='theme-btn  '>Please wait...</Button>
                                    </Box>
                                </>
                            } */}
                  {activeStep === 0 && (
                    <Box
                      className="heading-btns mt-40"
                      style={{ textAlign: "end" }}
                    >
                      <Button
                        onClick={handleNext}
                        variant="contained"
                        className="theme-btn  "
                      >
                        Next
                      </Button>
                    </Box>
                  )}
                </Box>
              )}

              {activeStep === 1 && (
                <Box className="steppy_content">
                  <Grid item xs={12} sm={12} md={12}>
                    <Box className=" ">
                      <Typography component="div" className="label">
                        Additional Images
                        <Typography component="span" className="star">
                          *
                        </Typography>
                      </Typography>

                      {state1.length < 1 && (
                        <Button
                          className="multiple_upload_btn"
                          variant="contained"
                          component="label"
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <FileUploadOutlinedIcon className="fz-18 mr-5" />{" "}
                            Upload
                          </Box>
                          <input
                            accept={"image/png, image/jpg,image/jpeg"}
                            onChange={multipleImgChangeMultiple}
                            hidden
                            multiple
                            type="file"
                          />
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  {imageAddError && (
                    <p style={{ color: "red" }} className="fz-13 m-0 ml-20">
                      {imageAddError}
                    </p>
                  )}
                  <Grid item xs={12} sm={12} md={12}>
                    {state1.length > 0 && (
                      <Grid
                        container
                        columnSpacing={1}
                        rowSpacing={1}
                        className="multiple_upload_container"
                      >
                        {state1.length > 0
                          ? state1.map((x, i) => (
                              <Grid item xs={6} sm={4} md={3} lg={3}>
                                <Box className="uploaded_img">
                                  <Button
                                    className="close_icon"
                                    onClick={(e) => indexDelete(e, i)}
                                  >
                                    <Close />
                                  </Button>
                                  <img
                                    src={
                                      x.slice(0, 4) === "blob"
                                        ? x
                                        : process.env.REACT_APP_BASE_URL +
                                          `${x}`
                                    }
                                    alt=""
                                    height={155}
                                    width={195}
                                  />
                                </Box>
                              </Grid>
                            ))
                          : ""}
                        <Grid item xs={6} sm={4} md={3} lg={3}>
                          <label
                            htmlFor="contained-button-file1"
                            className="w-full"
                          >
                            <Box className="multiple_upload_btn">
                              <Input
                                inputProps={{
                                  accept: "image/png, image/jpg,image/jpeg",
                                }}
                                onChange={multipleImgChangeMultiple}
                                style={{ display: "none" }}
                                id="contained-button-file1"
                                type="file"
                              />
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <FileUploadOutlinedIcon />
                              </Box>
                            </Box>
                          </label>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <Typography
                      className=" fz-12 mt-10 mb-10"
                      component="p"
                      variant="p"
                    >
                      <strong>Note:</strong> Image size should be more than
                      280px * 280px for better quality picture.
                    </Typography>
                  </Grid>

                  <Grid item xs={12} sm={12} md={12} style={{ marginTop: 20 }}>
                    <Box className="mb-10">
                      <Typography component="div" className="label">
                        Short Description
                      </Typography>

                      <FormControl variant="standard" fullWidth>
                        <textarea
                          placeholder="Short Description "
                          className="textarea"
                          defaultValue={description}
                          onChange={(e) => setDescription(e.target.value)}
                          rows={4}
                          cols={40}
                          style={{ resize: "none" }}
                        />
                      </FormControl>
                    </Box>
                  </Grid>

                  {activeStep === 1 && (
                    <Box
                      className="heading-btns mt-20"
                      style={{ textAlign: "end" }}
                    >
                      <Button
                        onClick={handleBack}
                        variant="contained"
                        className="theme-btn  mr-10"
                      >
                        Back
                      </Button>
                      {truebutton === false ? (
                        <Button
                          onClick={(e) => handleEditFanPage(e, "add")}
                          variant="contained"
                          className="theme-btn"
                        >
                          Update
                        </Button>
                      ) : (
                        <Button
                          variant="contained"
                          className="theme-btn  mr-10"
                        >
                          Please wait...
                        </Button>
                      )}
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          </Grid>
        </Box>
      </Box>
    );
}